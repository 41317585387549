import { default as editVARUgVrN7wMeta } from "/codebuild/output/src2629404069/src/pages/admin/account_plans/[account_plan_id]/edit.vue?macro=true";
import { default as indexJsgn1fhPB9Meta } from "/codebuild/output/src2629404069/src/pages/admin/account_plans/[account_plan_id]/index.vue?macro=true";
import { default as _91account_plan_id_93nNRNh9uPESMeta } from "/codebuild/output/src2629404069/src/pages/admin/account_plans/[account_plan_id].vue?macro=true";
import { default as editvixUi7Nwd1Meta } from "/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexpfUp7u1j5JMeta } from "/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93GxzpTzzJJ8Meta } from "/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexKmGb7dB8kiMeta } from "/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/index.vue?macro=true";
import { default as newaKfloqpLW7Meta } from "/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/new.vue?macro=true";
import { default as account_requested_planslAW2ju2ConMeta } from "/codebuild/output/src2629404069/src/pages/admin/account_requested_plans.vue?macro=true";
import { default as editdxupqjCa7BMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexKu2G9Yw81IMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93gnMZhoksEmMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexDuFlRmn8H7Meta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue?macro=true";
import { default as newJTSOQOK6KqMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plans9upzHVMPbeMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans.vue?macro=true";
import { default as editkOPH7QwjmMMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexSEducAXuh1Meta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93c8vkyE63YfMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexB9KHJpTx5XMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/index.vue?macro=true";
import { default as newBVkLPPCd9ZMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/new.vue?macro=true";
import { default as caller_idsLn022xo2zWMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids.vue?macro=true";
import { default as edithhqefTKjGzMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/edit.vue?macro=true";
import { default as indexZQrevwMWdiMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/index.vue?macro=true";
import { default as editsb0fSpSVdFMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexPudvoknefuMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93tIAFfMLOyjMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexs9ngT2gukfMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue?macro=true";
import { default as newlorRZ3q6AVMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsKzYtxQ7UTKMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings.vue?macro=true";
import { default as _91account_id_93ap03qs05BtMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id].vue?macro=true";
import { default as indexGIg9ldhEPFMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/index.vue?macro=true";
import { default as wizard_plan_selectionSjObnToM4ZMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/wizard_plan_selection.vue?macro=true";
import { default as wizard21zIdGBL4vMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts/wizard.vue?macro=true";
import { default as accounts7XqTG82GqAMeta } from "/codebuild/output/src2629404069/src/pages/admin/accounts.vue?macro=true";
import { default as editWdPCIwogwKMeta } from "/codebuild/output/src2629404069/src/pages/admin/admin_users/[admin_user_id]/edit.vue?macro=true";
import { default as index78n7b8Llx8Meta } from "/codebuild/output/src2629404069/src/pages/admin/admin_users/[admin_user_id]/index.vue?macro=true";
import { default as _91admin_user_id_936Oczye1x8qMeta } from "/codebuild/output/src2629404069/src/pages/admin/admin_users/[admin_user_id].vue?macro=true";
import { default as index9yGN13IflHMeta } from "/codebuild/output/src2629404069/src/pages/admin/admin_users/index.vue?macro=true";
import { default as newZqtoWFzDZ0Meta } from "/codebuild/output/src2629404069/src/pages/admin/admin_users/new.vue?macro=true";
import { default as admin_usersq52VdkSJtdMeta } from "/codebuild/output/src2629404069/src/pages/admin/admin_users.vue?macro=true";
import { default as indexPCLtbV0pYTMeta } from "/codebuild/output/src2629404069/src/pages/admin/audit_trails/index.vue?macro=true";
import { default as audit_trailsqz2hDY0RcjMeta } from "/codebuild/output/src2629404069/src/pages/admin/audit_trails.vue?macro=true";
import { default as editGYlYjUSm1YMeta } from "/codebuild/output/src2629404069/src/pages/admin/billings/[billing_id]/edit.vue?macro=true";
import { default as indexJ8ZxVaQDnkMeta } from "/codebuild/output/src2629404069/src/pages/admin/billings/[billing_id]/index.vue?macro=true";
import { default as _91billing_id_93yx7smjWAqPMeta } from "/codebuild/output/src2629404069/src/pages/admin/billings/[billing_id].vue?macro=true";
import { default as indexXCjgFNIHymMeta } from "/codebuild/output/src2629404069/src/pages/admin/billings/index.vue?macro=true";
import { default as newBMib2oqYfvMeta } from "/codebuild/output/src2629404069/src/pages/admin/billings/new.vue?macro=true";
import { default as billingsodUIMv7GwdMeta } from "/codebuild/output/src2629404069/src/pages/admin/billings.vue?macro=true";
import { default as editMl9BPyDgciMeta } from "/codebuild/output/src2629404069/src/pages/admin/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexchewhFtxdSMeta } from "/codebuild/output/src2629404069/src/pages/admin/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93fJMyWxiifDMeta } from "/codebuild/output/src2629404069/src/pages/admin/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexj5gDTgUC94Meta } from "/codebuild/output/src2629404069/src/pages/admin/caller_ids/index.vue?macro=true";
import { default as newirpnoY0ogpMeta } from "/codebuild/output/src2629404069/src/pages/admin/caller_ids/new.vue?macro=true";
import { default as caller_idsi8TnfHuudeMeta } from "/codebuild/output/src2629404069/src/pages/admin/caller_ids.vue?macro=true";
import { default as editIIhp2FOm2AMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue?macro=true";
import { default as indexxmi6kerp3RMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue?macro=true";
import { default as _91campaign_setting_id_93QzSA5Di97IMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaign_settings/[campaign_setting_id].vue?macro=true";
import { default as editpfNUaymiM8Meta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/edit.vue?macro=true";
import { default as index9hYynkvplaMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/index.vue?macro=true";
import { default as editiJ3VL3wiSgMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue?macro=true";
import { default as indexXYq0DukjXuMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue?macro=true";
import { default as _91outbound_call_transaction_id_93sc0Nc8QsEjMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue?macro=true";
import { default as indexbEoZohSVNMMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue?macro=true";
import { default as newpVIZxYvXlZMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue?macro=true";
import { default as outbound_call_transactionsIutCK97WqAMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue?macro=true";
import { default as _91campaign_id_93pG4O5CBvb9Meta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id].vue?macro=true";
import { default as indexOG8giaH5NYMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/index.vue?macro=true";
import { default as newDvxyPJp9HmMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns/new.vue?macro=true";
import { default as campaignsIgxrmblYfvMeta } from "/codebuild/output/src2629404069/src/pages/admin/campaigns.vue?macro=true";
import { default as indexDE15by1srvMeta } from "/codebuild/output/src2629404069/src/pages/admin/custom_emails/[custom_email_id]/index.vue?macro=true";
import { default as _91custom_email_id_93nrMVct9RNIMeta } from "/codebuild/output/src2629404069/src/pages/admin/custom_emails/[custom_email_id].vue?macro=true";
import { default as index9recMGSkfjMeta } from "/codebuild/output/src2629404069/src/pages/admin/custom_emails/index.vue?macro=true";
import { default as newgUTzZrhtRLMeta } from "/codebuild/output/src2629404069/src/pages/admin/custom_emails/new.vue?macro=true";
import { default as custom_emailsstdALY9juwMeta } from "/codebuild/output/src2629404069/src/pages/admin/custom_emails.vue?macro=true";
import { default as index33KPrgDSwkMeta } from "/codebuild/output/src2629404069/src/pages/admin/dashboard/index.vue?macro=true";
import { default as dashboardfaocA6n5aeMeta } from "/codebuild/output/src2629404069/src/pages/admin/dashboard.vue?macro=true";
import { default as indexeh4al55gzeMeta } from "/codebuild/output/src2629404069/src/pages/admin/inquiries/[inquiry_id]/index.vue?macro=true";
import { default as _91inquiry_id_93kCmEoQucfuMeta } from "/codebuild/output/src2629404069/src/pages/admin/inquiries/[inquiry_id].vue?macro=true";
import { default as indexEc9wxnDjKAMeta } from "/codebuild/output/src2629404069/src/pages/admin/inquiries/index.vue?macro=true";
import { default as newIY6p5EkS1FMeta } from "/codebuild/output/src2629404069/src/pages/admin/inquiries/new.vue?macro=true";
import { default as inquiriesri1essSz2iMeta } from "/codebuild/output/src2629404069/src/pages/admin/inquiries.vue?macro=true";
import { default as indexCh6sBrvVc8Meta } from "/codebuild/output/src2629404069/src/pages/admin/login/index.vue?macro=true";
import { default as loginCIeMX2uqdpMeta } from "/codebuild/output/src2629404069/src/pages/admin/login.vue?macro=true";
import { default as indexv9A3gbs7VfMeta } from "/codebuild/output/src2629404069/src/pages/admin/password/edit/index.vue?macro=true";
import { default as indexwtyeLxVfMDMeta } from "/codebuild/output/src2629404069/src/pages/admin/password/new/index.vue?macro=true";
import { default as index238RAtLtoRMeta } from "/codebuild/output/src2629404069/src/pages/admin/reports/index.vue?macro=true";
import { default as reportsS9rbgMRymmMeta } from "/codebuild/output/src2629404069/src/pages/admin/reports.vue?macro=true";
import { default as editt0MZKOY5IMMeta } from "/codebuild/output/src2629404069/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexaXplDGONpOMeta } from "/codebuild/output/src2629404069/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93LFRBpnbDpbMeta } from "/codebuild/output/src2629404069/src/pages/admin/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as index6VaR6uKe7LMeta } from "/codebuild/output/src2629404069/src/pages/admin/sms_maskings/index.vue?macro=true";
import { default as newNGy1oAs4sjMeta } from "/codebuild/output/src2629404069/src/pages/admin/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsR47QWz488DMeta } from "/codebuild/output/src2629404069/src/pages/admin/sms_maskings.vue?macro=true";
import { default as editjjAratyeNBMeta } from "/codebuild/output/src2629404069/src/pages/admin/users/[user_id]/edit.vue?macro=true";
import { default as indexLQkKsvWJC3Meta } from "/codebuild/output/src2629404069/src/pages/admin/users/[user_id]/index.vue?macro=true";
import { default as _91user_id_93yRF7YDcCrsMeta } from "/codebuild/output/src2629404069/src/pages/admin/users/[user_id].vue?macro=true";
import { default as indexGTozZxOZ0WMeta } from "/codebuild/output/src2629404069/src/pages/admin/users/index.vue?macro=true";
import { default as newHrBvNVOWDmMeta } from "/codebuild/output/src2629404069/src/pages/admin/users/new.vue?macro=true";
import { default as usersRmKVJEnZb1Meta } from "/codebuild/output/src2629404069/src/pages/admin/users.vue?macro=true";
import { default as editbas1ekU4uJMeta } from "/codebuild/output/src2629404069/src/pages/admin/wallets/[wallet_id]/edit.vue?macro=true";
import { default as indexGYEyzat1JVMeta } from "/codebuild/output/src2629404069/src/pages/admin/wallets/[wallet_id]/index.vue?macro=true";
import { default as _91wallet_id_93XhFpvfDPJoMeta } from "/codebuild/output/src2629404069/src/pages/admin/wallets/[wallet_id].vue?macro=true";
import { default as adminSDzTJMTvjOMeta } from "/codebuild/output/src2629404069/src/pages/admin.vue?macro=true";
import { default as indexHkPgG3KuZcMeta } from "/codebuild/output/src2629404069/src/pages/audit_logs/index.vue?macro=true";
import { default as indexTj2m7NxjEWMeta } from "/codebuild/output/src2629404069/src/pages/billings/index.vue?macro=true";
import { default as indexNbug36jrBZMeta } from "/codebuild/output/src2629404069/src/pages/call_out/reports/index.vue?macro=true";
import { default as _91id_93HNo7QRNDLLMeta } from "/codebuild/output/src2629404069/src/pages/call_recordings/[id].vue?macro=true";
import { default as index4w0yNFjVOOMeta } from "/codebuild/output/src2629404069/src/pages/campaigns/[id]/index.vue?macro=true";
import { default as indexyyAb1RfViXMeta } from "/codebuild/output/src2629404069/src/pages/campaigns/index.vue?macro=true";
import { default as indexNubuj8cJtvMeta } from "/codebuild/output/src2629404069/src/pages/dashboard/index.vue?macro=true";
import { default as indexlmdA6HVt6tMeta } from "/codebuild/output/src2629404069/src/pages/help/index.vue?macro=true";
import { default as indexAdf4pdiVpBMeta } from "/codebuild/output/src2629404069/src/pages/index.vue?macro=true";
import { default as indexzp2evHtCPhMeta } from "/codebuild/output/src2629404069/src/pages/inquiry/index.vue?macro=true";
import { default as editor82mGmUAhEMMeta } from "/codebuild/output/src2629404069/src/pages/ivr_trees/[id]/editor.vue?macro=true";
import { default as indexdnkxbmpfGAMeta } from "/codebuild/output/src2629404069/src/pages/ivr_trees/index.vue?macro=true";
import { default as verify_emailW68AxAuIMpMeta } from "/codebuild/output/src2629404069/src/pages/profiles/[user_id]/verify_email.vue?macro=true";
import { default as welcome_dashboardOrZRiws2Y2Meta } from "/codebuild/output/src2629404069/src/pages/profiles/[user_id]/welcome_dashboard.vue?macro=true";
import { default as indexkvIATVul48Meta } from "/codebuild/output/src2629404069/src/pages/profiles/index.vue?macro=true";
import { default as index9uaL6ksF3fMeta } from "/codebuild/output/src2629404069/src/pages/sub_users/users/index.vue?macro=true";
import { default as indexIrY3cZnDtjMeta } from "/codebuild/output/src2629404069/src/pages/terms_and_conditions/index.vue?macro=true";
import { default as indexd2gCgHB3AcMeta } from "/codebuild/output/src2629404069/src/pages/users/password/edit/index.vue?macro=true";
import { default as index2Oj4dRzG0wMeta } from "/codebuild/output/src2629404069/src/pages/users/password/new/index.vue?macro=true";
import { default as indexvJaLQpuemPMeta } from "/codebuild/output/src2629404069/src/pages/users/sign_in/index.vue?macro=true";
export default [
  {
    name: adminSDzTJMTvjOMeta?.name ?? "admin",
    path: adminSDzTJMTvjOMeta?.path ?? "/admin",
    children: [
  {
    path: _91account_plan_id_93nNRNh9uPESMeta?.path ?? "account_plans/:account_plan_id()",
    children: [
  {
    name: editVARUgVrN7wMeta?.name ?? "admin-account_plans-account_plan_id-edit",
    path: editVARUgVrN7wMeta?.path ?? "edit",
    meta: editVARUgVrN7wMeta || {},
    alias: editVARUgVrN7wMeta?.alias || [],
    redirect: editVARUgVrN7wMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/account_plans/[account_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexJsgn1fhPB9Meta?.name ?? "admin-account_plans-account_plan_id",
    path: indexJsgn1fhPB9Meta?.path ?? "",
    meta: indexJsgn1fhPB9Meta || {},
    alias: indexJsgn1fhPB9Meta?.alias || [],
    redirect: indexJsgn1fhPB9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/account_plans/[account_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_plan_id_93nNRNh9uPESMeta?.name ?? undefined,
    meta: _91account_plan_id_93nNRNh9uPESMeta || {},
    alias: _91account_plan_id_93nNRNh9uPESMeta?.alias || [],
    redirect: _91account_plan_id_93nNRNh9uPESMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/account_plans/[account_plan_id].vue").then(m => m.default || m)
  },
  {
    path: account_requested_planslAW2ju2ConMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93GxzpTzzJJ8Meta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editvixUi7Nwd1Meta?.name ?? "admin-account_requested_plans-account_requested_plan_id-edit",
    path: editvixUi7Nwd1Meta?.path ?? "edit",
    meta: editvixUi7Nwd1Meta || {},
    alias: editvixUi7Nwd1Meta?.alias || [],
    redirect: editvixUi7Nwd1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexpfUp7u1j5JMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id",
    path: indexpfUp7u1j5JMeta?.path ?? "",
    meta: indexpfUp7u1j5JMeta || {},
    alias: indexpfUp7u1j5JMeta?.alias || [],
    redirect: indexpfUp7u1j5JMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93GxzpTzzJJ8Meta?.name ?? undefined,
    meta: _91account_requested_plan_id_93GxzpTzzJJ8Meta || {},
    alias: _91account_requested_plan_id_93GxzpTzzJJ8Meta?.alias || [],
    redirect: _91account_requested_plan_id_93GxzpTzzJJ8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexKmGb7dB8kiMeta?.name ?? "admin-account_requested_plans",
    path: indexKmGb7dB8kiMeta?.path ?? "",
    meta: indexKmGb7dB8kiMeta || {},
    alias: indexKmGb7dB8kiMeta?.alias || [],
    redirect: indexKmGb7dB8kiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newaKfloqpLW7Meta?.name ?? "admin-account_requested_plans-new",
    path: newaKfloqpLW7Meta?.path ?? "new",
    meta: newaKfloqpLW7Meta || {},
    alias: newaKfloqpLW7Meta?.alias || [],
    redirect: newaKfloqpLW7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_planslAW2ju2ConMeta?.name ?? undefined,
    meta: account_requested_planslAW2ju2ConMeta || {},
    alias: account_requested_planslAW2ju2ConMeta?.alias || [],
    redirect: account_requested_planslAW2ju2ConMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: accounts7XqTG82GqAMeta?.path ?? "accounts",
    children: [
  {
    path: _91account_id_93ap03qs05BtMeta?.path ?? ":account_id()",
    children: [
  {
    path: account_requested_plans9upzHVMPbeMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93gnMZhoksEmMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editdxupqjCa7BMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id-edit",
    path: editdxupqjCa7BMeta?.path ?? "edit",
    meta: editdxupqjCa7BMeta || {},
    alias: editdxupqjCa7BMeta?.alias || [],
    redirect: editdxupqjCa7BMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexKu2G9Yw81IMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id",
    path: indexKu2G9Yw81IMeta?.path ?? "",
    meta: indexKu2G9Yw81IMeta || {},
    alias: indexKu2G9Yw81IMeta?.alias || [],
    redirect: indexKu2G9Yw81IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93gnMZhoksEmMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93gnMZhoksEmMeta || {},
    alias: _91account_requested_plan_id_93gnMZhoksEmMeta?.alias || [],
    redirect: _91account_requested_plan_id_93gnMZhoksEmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexDuFlRmn8H7Meta?.name ?? "admin-accounts-account_id-account_requested_plans",
    path: indexDuFlRmn8H7Meta?.path ?? "",
    meta: indexDuFlRmn8H7Meta || {},
    alias: indexDuFlRmn8H7Meta?.alias || [],
    redirect: indexDuFlRmn8H7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newJTSOQOK6KqMeta?.name ?? "admin-accounts-account_id-account_requested_plans-new",
    path: newJTSOQOK6KqMeta?.path ?? "new",
    meta: newJTSOQOK6KqMeta || {},
    alias: newJTSOQOK6KqMeta?.alias || [],
    redirect: newJTSOQOK6KqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plans9upzHVMPbeMeta?.name ?? undefined,
    meta: account_requested_plans9upzHVMPbeMeta || {},
    alias: account_requested_plans9upzHVMPbeMeta?.alias || [],
    redirect: account_requested_plans9upzHVMPbeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: caller_idsLn022xo2zWMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93c8vkyE63YfMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editkOPH7QwjmMMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id-edit",
    path: editkOPH7QwjmMMeta?.path ?? "edit",
    meta: editkOPH7QwjmMMeta || {},
    alias: editkOPH7QwjmMMeta?.alias || [],
    redirect: editkOPH7QwjmMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexSEducAXuh1Meta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id",
    path: indexSEducAXuh1Meta?.path ?? "",
    meta: indexSEducAXuh1Meta || {},
    alias: indexSEducAXuh1Meta?.alias || [],
    redirect: indexSEducAXuh1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93c8vkyE63YfMeta?.name ?? undefined,
    meta: _91caller_id_id_93c8vkyE63YfMeta || {},
    alias: _91caller_id_id_93c8vkyE63YfMeta?.alias || [],
    redirect: _91caller_id_id_93c8vkyE63YfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexB9KHJpTx5XMeta?.name ?? "admin-accounts-account_id-caller_ids",
    path: indexB9KHJpTx5XMeta?.path ?? "",
    meta: indexB9KHJpTx5XMeta || {},
    alias: indexB9KHJpTx5XMeta?.alias || [],
    redirect: indexB9KHJpTx5XMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newBVkLPPCd9ZMeta?.name ?? "admin-accounts-account_id-caller_ids-new",
    path: newBVkLPPCd9ZMeta?.path ?? "new",
    meta: newBVkLPPCd9ZMeta || {},
    alias: newBVkLPPCd9ZMeta?.alias || [],
    redirect: newBVkLPPCd9ZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsLn022xo2zWMeta?.name ?? undefined,
    meta: caller_idsLn022xo2zWMeta || {},
    alias: caller_idsLn022xo2zWMeta?.alias || [],
    redirect: caller_idsLn022xo2zWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/caller_ids.vue").then(m => m.default || m)
  },
  {
    name: edithhqefTKjGzMeta?.name ?? "admin-accounts-account_id-edit",
    path: edithhqefTKjGzMeta?.path ?? "edit",
    meta: edithhqefTKjGzMeta || {},
    alias: edithhqefTKjGzMeta?.alias || [],
    redirect: edithhqefTKjGzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZQrevwMWdiMeta?.name ?? "admin-accounts-account_id",
    path: indexZQrevwMWdiMeta?.path ?? "",
    meta: indexZQrevwMWdiMeta || {},
    alias: indexZQrevwMWdiMeta?.alias || [],
    redirect: indexZQrevwMWdiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsKzYtxQ7UTKMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93tIAFfMLOyjMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editsb0fSpSVdFMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id-edit",
    path: editsb0fSpSVdFMeta?.path ?? "edit",
    meta: editsb0fSpSVdFMeta || {},
    alias: editsb0fSpSVdFMeta?.alias || [],
    redirect: editsb0fSpSVdFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexPudvoknefuMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id",
    path: indexPudvoknefuMeta?.path ?? "",
    meta: indexPudvoknefuMeta || {},
    alias: indexPudvoknefuMeta?.alias || [],
    redirect: indexPudvoknefuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93tIAFfMLOyjMeta?.name ?? undefined,
    meta: _91sms_masking_id_93tIAFfMLOyjMeta || {},
    alias: _91sms_masking_id_93tIAFfMLOyjMeta?.alias || [],
    redirect: _91sms_masking_id_93tIAFfMLOyjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexs9ngT2gukfMeta?.name ?? "admin-accounts-account_id-sms_maskings",
    path: indexs9ngT2gukfMeta?.path ?? "",
    meta: indexs9ngT2gukfMeta || {},
    alias: indexs9ngT2gukfMeta?.alias || [],
    redirect: indexs9ngT2gukfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newlorRZ3q6AVMeta?.name ?? "admin-accounts-account_id-sms_maskings-new",
    path: newlorRZ3q6AVMeta?.path ?? "new",
    meta: newlorRZ3q6AVMeta || {},
    alias: newlorRZ3q6AVMeta?.alias || [],
    redirect: newlorRZ3q6AVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsKzYtxQ7UTKMeta?.name ?? undefined,
    meta: sms_maskingsKzYtxQ7UTKMeta || {},
    alias: sms_maskingsKzYtxQ7UTKMeta?.alias || [],
    redirect: sms_maskingsKzYtxQ7UTKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id]/sms_maskings.vue").then(m => m.default || m)
  }
],
    name: _91account_id_93ap03qs05BtMeta?.name ?? undefined,
    meta: _91account_id_93ap03qs05BtMeta || {},
    alias: _91account_id_93ap03qs05BtMeta?.alias || [],
    redirect: _91account_id_93ap03qs05BtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/[account_id].vue").then(m => m.default || m)
  },
  {
    name: indexGIg9ldhEPFMeta?.name ?? "admin-accounts",
    path: indexGIg9ldhEPFMeta?.path ?? "",
    meta: indexGIg9ldhEPFMeta || {},
    alias: indexGIg9ldhEPFMeta?.alias || [],
    redirect: indexGIg9ldhEPFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: wizard_plan_selectionSjObnToM4ZMeta?.name ?? "admin-accounts-wizard_plan_selection",
    path: wizard_plan_selectionSjObnToM4ZMeta?.path ?? "wizard_plan_selection",
    meta: wizard_plan_selectionSjObnToM4ZMeta || {},
    alias: wizard_plan_selectionSjObnToM4ZMeta?.alias || [],
    redirect: wizard_plan_selectionSjObnToM4ZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/wizard_plan_selection.vue").then(m => m.default || m)
  },
  {
    name: wizard21zIdGBL4vMeta?.name ?? "admin-accounts-wizard",
    path: wizard21zIdGBL4vMeta?.path ?? "wizard",
    meta: wizard21zIdGBL4vMeta || {},
    alias: wizard21zIdGBL4vMeta?.alias || [],
    redirect: wizard21zIdGBL4vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts/wizard.vue").then(m => m.default || m)
  }
],
    name: accounts7XqTG82GqAMeta?.name ?? undefined,
    meta: accounts7XqTG82GqAMeta || {},
    alias: accounts7XqTG82GqAMeta?.alias || [],
    redirect: accounts7XqTG82GqAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    path: admin_usersq52VdkSJtdMeta?.path ?? "admin_users",
    children: [
  {
    path: _91admin_user_id_936Oczye1x8qMeta?.path ?? ":admin_user_id()",
    children: [
  {
    name: editWdPCIwogwKMeta?.name ?? "admin-admin_users-admin_user_id-edit",
    path: editWdPCIwogwKMeta?.path ?? "edit",
    meta: editWdPCIwogwKMeta || {},
    alias: editWdPCIwogwKMeta?.alias || [],
    redirect: editWdPCIwogwKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/admin_users/[admin_user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index78n7b8Llx8Meta?.name ?? "admin-admin_users-admin_user_id",
    path: index78n7b8Llx8Meta?.path ?? "",
    meta: index78n7b8Llx8Meta || {},
    alias: index78n7b8Llx8Meta?.alias || [],
    redirect: index78n7b8Llx8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/admin_users/[admin_user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91admin_user_id_936Oczye1x8qMeta?.name ?? undefined,
    meta: _91admin_user_id_936Oczye1x8qMeta || {},
    alias: _91admin_user_id_936Oczye1x8qMeta?.alias || [],
    redirect: _91admin_user_id_936Oczye1x8qMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/admin_users/[admin_user_id].vue").then(m => m.default || m)
  },
  {
    name: index9yGN13IflHMeta?.name ?? "admin-admin_users",
    path: index9yGN13IflHMeta?.path ?? "",
    meta: index9yGN13IflHMeta || {},
    alias: index9yGN13IflHMeta?.alias || [],
    redirect: index9yGN13IflHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/admin_users/index.vue").then(m => m.default || m)
  },
  {
    name: newZqtoWFzDZ0Meta?.name ?? "admin-admin_users-new",
    path: newZqtoWFzDZ0Meta?.path ?? "new",
    meta: newZqtoWFzDZ0Meta || {},
    alias: newZqtoWFzDZ0Meta?.alias || [],
    redirect: newZqtoWFzDZ0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/admin_users/new.vue").then(m => m.default || m)
  }
],
    name: admin_usersq52VdkSJtdMeta?.name ?? undefined,
    meta: admin_usersq52VdkSJtdMeta || {},
    alias: admin_usersq52VdkSJtdMeta?.alias || [],
    redirect: admin_usersq52VdkSJtdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/admin_users.vue").then(m => m.default || m)
  },
  {
    path: audit_trailsqz2hDY0RcjMeta?.path ?? "audit_trails",
    children: [
  {
    name: indexPCLtbV0pYTMeta?.name ?? "admin-audit_trails",
    path: indexPCLtbV0pYTMeta?.path ?? "",
    meta: indexPCLtbV0pYTMeta || {},
    alias: indexPCLtbV0pYTMeta?.alias || [],
    redirect: indexPCLtbV0pYTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/audit_trails/index.vue").then(m => m.default || m)
  }
],
    name: audit_trailsqz2hDY0RcjMeta?.name ?? undefined,
    meta: audit_trailsqz2hDY0RcjMeta || {},
    alias: audit_trailsqz2hDY0RcjMeta?.alias || [],
    redirect: audit_trailsqz2hDY0RcjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/audit_trails.vue").then(m => m.default || m)
  },
  {
    path: billingsodUIMv7GwdMeta?.path ?? "billings",
    children: [
  {
    path: _91billing_id_93yx7smjWAqPMeta?.path ?? ":billing_id()",
    children: [
  {
    name: editGYlYjUSm1YMeta?.name ?? "admin-billings-billing_id-edit",
    path: editGYlYjUSm1YMeta?.path ?? "edit",
    meta: editGYlYjUSm1YMeta || {},
    alias: editGYlYjUSm1YMeta?.alias || [],
    redirect: editGYlYjUSm1YMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/billings/[billing_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexJ8ZxVaQDnkMeta?.name ?? "admin-billings-billing_id",
    path: indexJ8ZxVaQDnkMeta?.path ?? "",
    meta: indexJ8ZxVaQDnkMeta || {},
    alias: indexJ8ZxVaQDnkMeta?.alias || [],
    redirect: indexJ8ZxVaQDnkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/billings/[billing_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91billing_id_93yx7smjWAqPMeta?.name ?? undefined,
    meta: _91billing_id_93yx7smjWAqPMeta || {},
    alias: _91billing_id_93yx7smjWAqPMeta?.alias || [],
    redirect: _91billing_id_93yx7smjWAqPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/billings/[billing_id].vue").then(m => m.default || m)
  },
  {
    name: indexXCjgFNIHymMeta?.name ?? "admin-billings",
    path: indexXCjgFNIHymMeta?.path ?? "",
    meta: indexXCjgFNIHymMeta || {},
    alias: indexXCjgFNIHymMeta?.alias || [],
    redirect: indexXCjgFNIHymMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/billings/index.vue").then(m => m.default || m)
  },
  {
    name: newBMib2oqYfvMeta?.name ?? "admin-billings-new",
    path: newBMib2oqYfvMeta?.path ?? "new",
    meta: newBMib2oqYfvMeta || {},
    alias: newBMib2oqYfvMeta?.alias || [],
    redirect: newBMib2oqYfvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/billings/new.vue").then(m => m.default || m)
  }
],
    name: billingsodUIMv7GwdMeta?.name ?? undefined,
    meta: billingsodUIMv7GwdMeta || {},
    alias: billingsodUIMv7GwdMeta?.alias || [],
    redirect: billingsodUIMv7GwdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/billings.vue").then(m => m.default || m)
  },
  {
    path: caller_idsi8TnfHuudeMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93fJMyWxiifDMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editMl9BPyDgciMeta?.name ?? "admin-caller_ids-caller_id_id-edit",
    path: editMl9BPyDgciMeta?.path ?? "edit",
    meta: editMl9BPyDgciMeta || {},
    alias: editMl9BPyDgciMeta?.alias || [],
    redirect: editMl9BPyDgciMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexchewhFtxdSMeta?.name ?? "admin-caller_ids-caller_id_id",
    path: indexchewhFtxdSMeta?.path ?? "",
    meta: indexchewhFtxdSMeta || {},
    alias: indexchewhFtxdSMeta?.alias || [],
    redirect: indexchewhFtxdSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93fJMyWxiifDMeta?.name ?? undefined,
    meta: _91caller_id_id_93fJMyWxiifDMeta || {},
    alias: _91caller_id_id_93fJMyWxiifDMeta?.alias || [],
    redirect: _91caller_id_id_93fJMyWxiifDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexj5gDTgUC94Meta?.name ?? "admin-caller_ids",
    path: indexj5gDTgUC94Meta?.path ?? "",
    meta: indexj5gDTgUC94Meta || {},
    alias: indexj5gDTgUC94Meta?.alias || [],
    redirect: indexj5gDTgUC94Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newirpnoY0ogpMeta?.name ?? "admin-caller_ids-new",
    path: newirpnoY0ogpMeta?.path ?? "new",
    meta: newirpnoY0ogpMeta || {},
    alias: newirpnoY0ogpMeta?.alias || [],
    redirect: newirpnoY0ogpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsi8TnfHuudeMeta?.name ?? undefined,
    meta: caller_idsi8TnfHuudeMeta || {},
    alias: caller_idsi8TnfHuudeMeta?.alias || [],
    redirect: caller_idsi8TnfHuudeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/caller_ids.vue").then(m => m.default || m)
  },
  {
    path: _91campaign_setting_id_93QzSA5Di97IMeta?.path ?? "campaign_settings/:campaign_setting_id()",
    children: [
  {
    name: editIIhp2FOm2AMeta?.name ?? "admin-campaign_settings-campaign_setting_id-edit",
    path: editIIhp2FOm2AMeta?.path ?? "edit",
    meta: editIIhp2FOm2AMeta || {},
    alias: editIIhp2FOm2AMeta?.alias || [],
    redirect: editIIhp2FOm2AMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexxmi6kerp3RMeta?.name ?? "admin-campaign_settings-campaign_setting_id",
    path: indexxmi6kerp3RMeta?.path ?? "",
    meta: indexxmi6kerp3RMeta || {},
    alias: indexxmi6kerp3RMeta?.alias || [],
    redirect: indexxmi6kerp3RMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91campaign_setting_id_93QzSA5Di97IMeta?.name ?? undefined,
    meta: _91campaign_setting_id_93QzSA5Di97IMeta || {},
    alias: _91campaign_setting_id_93QzSA5Di97IMeta?.alias || [],
    redirect: _91campaign_setting_id_93QzSA5Di97IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaign_settings/[campaign_setting_id].vue").then(m => m.default || m)
  },
  {
    path: campaignsIgxrmblYfvMeta?.path ?? "campaigns",
    children: [
  {
    path: _91campaign_id_93pG4O5CBvb9Meta?.path ?? ":campaign_id()",
    children: [
  {
    name: editpfNUaymiM8Meta?.name ?? "admin-campaigns-campaign_id-edit",
    path: editpfNUaymiM8Meta?.path ?? "edit",
    meta: editpfNUaymiM8Meta || {},
    alias: editpfNUaymiM8Meta?.alias || [],
    redirect: editpfNUaymiM8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index9hYynkvplaMeta?.name ?? "admin-campaigns-campaign_id",
    path: index9hYynkvplaMeta?.path ?? "",
    meta: index9hYynkvplaMeta || {},
    alias: index9hYynkvplaMeta?.alias || [],
    redirect: index9hYynkvplaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/index.vue").then(m => m.default || m)
  },
  {
    path: outbound_call_transactionsIutCK97WqAMeta?.path ?? "outbound_call_transactions",
    children: [
  {
    path: _91outbound_call_transaction_id_93sc0Nc8QsEjMeta?.path ?? ":outbound_call_transaction_id()",
    children: [
  {
    name: editiJ3VL3wiSgMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id-edit",
    path: editiJ3VL3wiSgMeta?.path ?? "edit",
    meta: editiJ3VL3wiSgMeta || {},
    alias: editiJ3VL3wiSgMeta?.alias || [],
    redirect: editiJ3VL3wiSgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexXYq0DukjXuMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id",
    path: indexXYq0DukjXuMeta?.path ?? "",
    meta: indexXYq0DukjXuMeta || {},
    alias: indexXYq0DukjXuMeta?.alias || [],
    redirect: indexXYq0DukjXuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91outbound_call_transaction_id_93sc0Nc8QsEjMeta?.name ?? undefined,
    meta: _91outbound_call_transaction_id_93sc0Nc8QsEjMeta || {},
    alias: _91outbound_call_transaction_id_93sc0Nc8QsEjMeta?.alias || [],
    redirect: _91outbound_call_transaction_id_93sc0Nc8QsEjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue").then(m => m.default || m)
  },
  {
    name: indexbEoZohSVNMMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions",
    path: indexbEoZohSVNMMeta?.path ?? "",
    meta: indexbEoZohSVNMMeta || {},
    alias: indexbEoZohSVNMMeta?.alias || [],
    redirect: indexbEoZohSVNMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue").then(m => m.default || m)
  },
  {
    name: newpVIZxYvXlZMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-new",
    path: newpVIZxYvXlZMeta?.path ?? "new",
    meta: newpVIZxYvXlZMeta || {},
    alias: newpVIZxYvXlZMeta?.alias || [],
    redirect: newpVIZxYvXlZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue").then(m => m.default || m)
  }
],
    name: outbound_call_transactionsIutCK97WqAMeta?.name ?? undefined,
    meta: outbound_call_transactionsIutCK97WqAMeta || {},
    alias: outbound_call_transactionsIutCK97WqAMeta?.alias || [],
    redirect: outbound_call_transactionsIutCK97WqAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue").then(m => m.default || m)
  }
],
    name: _91campaign_id_93pG4O5CBvb9Meta?.name ?? undefined,
    meta: _91campaign_id_93pG4O5CBvb9Meta || {},
    alias: _91campaign_id_93pG4O5CBvb9Meta?.alias || [],
    redirect: _91campaign_id_93pG4O5CBvb9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/[campaign_id].vue").then(m => m.default || m)
  },
  {
    name: indexOG8giaH5NYMeta?.name ?? "admin-campaigns",
    path: indexOG8giaH5NYMeta?.path ?? "",
    meta: indexOG8giaH5NYMeta || {},
    alias: indexOG8giaH5NYMeta?.alias || [],
    redirect: indexOG8giaH5NYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newDvxyPJp9HmMeta?.name ?? "admin-campaigns-new",
    path: newDvxyPJp9HmMeta?.path ?? "new",
    meta: newDvxyPJp9HmMeta || {},
    alias: newDvxyPJp9HmMeta?.alias || [],
    redirect: newDvxyPJp9HmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsIgxrmblYfvMeta?.name ?? undefined,
    meta: campaignsIgxrmblYfvMeta || {},
    alias: campaignsIgxrmblYfvMeta?.alias || [],
    redirect: campaignsIgxrmblYfvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/campaigns.vue").then(m => m.default || m)
  },
  {
    path: custom_emailsstdALY9juwMeta?.path ?? "custom_emails",
    children: [
  {
    path: _91custom_email_id_93nrMVct9RNIMeta?.path ?? ":custom_email_id()",
    children: [
  {
    name: indexDE15by1srvMeta?.name ?? "admin-custom_emails-custom_email_id",
    path: indexDE15by1srvMeta?.path ?? "",
    meta: indexDE15by1srvMeta || {},
    alias: indexDE15by1srvMeta?.alias || [],
    redirect: indexDE15by1srvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/custom_emails/[custom_email_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91custom_email_id_93nrMVct9RNIMeta?.name ?? undefined,
    meta: _91custom_email_id_93nrMVct9RNIMeta || {},
    alias: _91custom_email_id_93nrMVct9RNIMeta?.alias || [],
    redirect: _91custom_email_id_93nrMVct9RNIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/custom_emails/[custom_email_id].vue").then(m => m.default || m)
  },
  {
    name: index9recMGSkfjMeta?.name ?? "admin-custom_emails",
    path: index9recMGSkfjMeta?.path ?? "",
    meta: index9recMGSkfjMeta || {},
    alias: index9recMGSkfjMeta?.alias || [],
    redirect: index9recMGSkfjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/custom_emails/index.vue").then(m => m.default || m)
  },
  {
    name: newgUTzZrhtRLMeta?.name ?? "admin-custom_emails-new",
    path: newgUTzZrhtRLMeta?.path ?? "new",
    meta: newgUTzZrhtRLMeta || {},
    alias: newgUTzZrhtRLMeta?.alias || [],
    redirect: newgUTzZrhtRLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/custom_emails/new.vue").then(m => m.default || m)
  }
],
    name: custom_emailsstdALY9juwMeta?.name ?? undefined,
    meta: custom_emailsstdALY9juwMeta || {},
    alias: custom_emailsstdALY9juwMeta?.alias || [],
    redirect: custom_emailsstdALY9juwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/custom_emails.vue").then(m => m.default || m)
  },
  {
    path: dashboardfaocA6n5aeMeta?.path ?? "dashboard",
    children: [
  {
    name: index33KPrgDSwkMeta?.name ?? "admin-dashboard",
    path: index33KPrgDSwkMeta?.path ?? "",
    meta: index33KPrgDSwkMeta || {},
    alias: index33KPrgDSwkMeta?.alias || [],
    redirect: index33KPrgDSwkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboardfaocA6n5aeMeta?.name ?? undefined,
    meta: dashboardfaocA6n5aeMeta || {},
    alias: dashboardfaocA6n5aeMeta?.alias || [],
    redirect: dashboardfaocA6n5aeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    path: inquiriesri1essSz2iMeta?.path ?? "inquiries",
    children: [
  {
    path: _91inquiry_id_93kCmEoQucfuMeta?.path ?? ":inquiry_id()",
    children: [
  {
    name: indexeh4al55gzeMeta?.name ?? "admin-inquiries-inquiry_id",
    path: indexeh4al55gzeMeta?.path ?? "",
    meta: indexeh4al55gzeMeta || {},
    alias: indexeh4al55gzeMeta?.alias || [],
    redirect: indexeh4al55gzeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/inquiries/[inquiry_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91inquiry_id_93kCmEoQucfuMeta?.name ?? undefined,
    meta: _91inquiry_id_93kCmEoQucfuMeta || {},
    alias: _91inquiry_id_93kCmEoQucfuMeta?.alias || [],
    redirect: _91inquiry_id_93kCmEoQucfuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/inquiries/[inquiry_id].vue").then(m => m.default || m)
  },
  {
    name: indexEc9wxnDjKAMeta?.name ?? "admin-inquiries",
    path: indexEc9wxnDjKAMeta?.path ?? "",
    meta: indexEc9wxnDjKAMeta || {},
    alias: indexEc9wxnDjKAMeta?.alias || [],
    redirect: indexEc9wxnDjKAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: newIY6p5EkS1FMeta?.name ?? "admin-inquiries-new",
    path: newIY6p5EkS1FMeta?.path ?? "new",
    meta: newIY6p5EkS1FMeta || {},
    alias: newIY6p5EkS1FMeta?.alias || [],
    redirect: newIY6p5EkS1FMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/inquiries/new.vue").then(m => m.default || m)
  }
],
    name: inquiriesri1essSz2iMeta?.name ?? undefined,
    meta: inquiriesri1essSz2iMeta || {},
    alias: inquiriesri1essSz2iMeta?.alias || [],
    redirect: inquiriesri1essSz2iMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/inquiries.vue").then(m => m.default || m)
  },
  {
    path: loginCIeMX2uqdpMeta?.path ?? "login",
    children: [
  {
    name: indexCh6sBrvVc8Meta?.name ?? "admin-login",
    path: indexCh6sBrvVc8Meta?.path ?? "",
    meta: indexCh6sBrvVc8Meta || {},
    alias: indexCh6sBrvVc8Meta?.alias || [],
    redirect: indexCh6sBrvVc8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/login/index.vue").then(m => m.default || m)
  }
],
    name: loginCIeMX2uqdpMeta?.name ?? undefined,
    meta: loginCIeMX2uqdpMeta || {},
    alias: loginCIeMX2uqdpMeta?.alias || [],
    redirect: loginCIeMX2uqdpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: indexv9A3gbs7VfMeta?.name ?? "admin-password-edit",
    path: indexv9A3gbs7VfMeta?.path ?? "password/edit",
    meta: indexv9A3gbs7VfMeta || {},
    alias: indexv9A3gbs7VfMeta?.alias || [],
    redirect: indexv9A3gbs7VfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexwtyeLxVfMDMeta?.name ?? "admin-password-new",
    path: indexwtyeLxVfMDMeta?.path ?? "password/new",
    meta: indexwtyeLxVfMDMeta || {},
    alias: indexwtyeLxVfMDMeta?.alias || [],
    redirect: indexwtyeLxVfMDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/password/new/index.vue").then(m => m.default || m)
  },
  {
    path: reportsS9rbgMRymmMeta?.path ?? "reports",
    children: [
  {
    name: index238RAtLtoRMeta?.name ?? "admin-reports",
    path: index238RAtLtoRMeta?.path ?? "",
    meta: index238RAtLtoRMeta || {},
    alias: index238RAtLtoRMeta?.alias || [],
    redirect: index238RAtLtoRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportsS9rbgMRymmMeta?.name ?? undefined,
    meta: reportsS9rbgMRymmMeta || {},
    alias: reportsS9rbgMRymmMeta?.alias || [],
    redirect: reportsS9rbgMRymmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsR47QWz488DMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93LFRBpnbDpbMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editt0MZKOY5IMMeta?.name ?? "admin-sms_maskings-sms_masking_id-edit",
    path: editt0MZKOY5IMMeta?.path ?? "edit",
    meta: editt0MZKOY5IMMeta || {},
    alias: editt0MZKOY5IMMeta?.alias || [],
    redirect: editt0MZKOY5IMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexaXplDGONpOMeta?.name ?? "admin-sms_maskings-sms_masking_id",
    path: indexaXplDGONpOMeta?.path ?? "",
    meta: indexaXplDGONpOMeta || {},
    alias: indexaXplDGONpOMeta?.alias || [],
    redirect: indexaXplDGONpOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93LFRBpnbDpbMeta?.name ?? undefined,
    meta: _91sms_masking_id_93LFRBpnbDpbMeta || {},
    alias: _91sms_masking_id_93LFRBpnbDpbMeta?.alias || [],
    redirect: _91sms_masking_id_93LFRBpnbDpbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: index6VaR6uKe7LMeta?.name ?? "admin-sms_maskings",
    path: index6VaR6uKe7LMeta?.path ?? "",
    meta: index6VaR6uKe7LMeta || {},
    alias: index6VaR6uKe7LMeta?.alias || [],
    redirect: index6VaR6uKe7LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newNGy1oAs4sjMeta?.name ?? "admin-sms_maskings-new",
    path: newNGy1oAs4sjMeta?.path ?? "new",
    meta: newNGy1oAs4sjMeta || {},
    alias: newNGy1oAs4sjMeta?.alias || [],
    redirect: newNGy1oAs4sjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsR47QWz488DMeta?.name ?? undefined,
    meta: sms_maskingsR47QWz488DMeta || {},
    alias: sms_maskingsR47QWz488DMeta?.alias || [],
    redirect: sms_maskingsR47QWz488DMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/sms_maskings.vue").then(m => m.default || m)
  },
  {
    path: usersRmKVJEnZb1Meta?.path ?? "users",
    children: [
  {
    path: _91user_id_93yRF7YDcCrsMeta?.path ?? ":user_id()",
    children: [
  {
    name: editjjAratyeNBMeta?.name ?? "admin-users-user_id-edit",
    path: editjjAratyeNBMeta?.path ?? "edit",
    meta: editjjAratyeNBMeta || {},
    alias: editjjAratyeNBMeta?.alias || [],
    redirect: editjjAratyeNBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexLQkKsvWJC3Meta?.name ?? "admin-users-user_id",
    path: indexLQkKsvWJC3Meta?.path ?? "",
    meta: indexLQkKsvWJC3Meta || {},
    alias: indexLQkKsvWJC3Meta?.alias || [],
    redirect: indexLQkKsvWJC3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/users/[user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91user_id_93yRF7YDcCrsMeta?.name ?? undefined,
    meta: _91user_id_93yRF7YDcCrsMeta || {},
    alias: _91user_id_93yRF7YDcCrsMeta?.alias || [],
    redirect: _91user_id_93yRF7YDcCrsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: indexGTozZxOZ0WMeta?.name ?? "admin-users",
    path: indexGTozZxOZ0WMeta?.path ?? "",
    meta: indexGTozZxOZ0WMeta || {},
    alias: indexGTozZxOZ0WMeta?.alias || [],
    redirect: indexGTozZxOZ0WMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newHrBvNVOWDmMeta?.name ?? "admin-users-new",
    path: newHrBvNVOWDmMeta?.path ?? "new",
    meta: newHrBvNVOWDmMeta || {},
    alias: newHrBvNVOWDmMeta?.alias || [],
    redirect: newHrBvNVOWDmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/users/new.vue").then(m => m.default || m)
  }
],
    name: usersRmKVJEnZb1Meta?.name ?? undefined,
    meta: usersRmKVJEnZb1Meta || {},
    alias: usersRmKVJEnZb1Meta?.alias || [],
    redirect: usersRmKVJEnZb1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: _91wallet_id_93XhFpvfDPJoMeta?.path ?? "wallets/:wallet_id()",
    children: [
  {
    name: editbas1ekU4uJMeta?.name ?? "admin-wallets-wallet_id-edit",
    path: editbas1ekU4uJMeta?.path ?? "edit",
    meta: editbas1ekU4uJMeta || {},
    alias: editbas1ekU4uJMeta?.alias || [],
    redirect: editbas1ekU4uJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/wallets/[wallet_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexGYEyzat1JVMeta?.name ?? "admin-wallets-wallet_id",
    path: indexGYEyzat1JVMeta?.path ?? "",
    meta: indexGYEyzat1JVMeta || {},
    alias: indexGYEyzat1JVMeta?.alias || [],
    redirect: indexGYEyzat1JVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/wallets/[wallet_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91wallet_id_93XhFpvfDPJoMeta?.name ?? undefined,
    meta: _91wallet_id_93XhFpvfDPJoMeta || {},
    alias: _91wallet_id_93XhFpvfDPJoMeta?.alias || [],
    redirect: _91wallet_id_93XhFpvfDPJoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin/wallets/[wallet_id].vue").then(m => m.default || m)
  }
],
    meta: adminSDzTJMTvjOMeta || {},
    alias: adminSDzTJMTvjOMeta?.alias || [],
    redirect: adminSDzTJMTvjOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexHkPgG3KuZcMeta?.name ?? "audit_logs",
    path: indexHkPgG3KuZcMeta?.path ?? "/audit_logs",
    meta: indexHkPgG3KuZcMeta || {},
    alias: indexHkPgG3KuZcMeta?.alias || [],
    redirect: indexHkPgG3KuZcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: indexTj2m7NxjEWMeta?.name ?? "billings",
    path: indexTj2m7NxjEWMeta?.path ?? "/billings",
    meta: indexTj2m7NxjEWMeta || {},
    alias: indexTj2m7NxjEWMeta?.alias || [],
    redirect: indexTj2m7NxjEWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/billings/index.vue").then(m => m.default || m)
  },
  {
    name: indexNbug36jrBZMeta?.name ?? "call_out-reports",
    path: indexNbug36jrBZMeta?.path ?? "/call_out/reports",
    meta: indexNbug36jrBZMeta || {},
    alias: indexNbug36jrBZMeta?.alias || [],
    redirect: indexNbug36jrBZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/call_out/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HNo7QRNDLLMeta?.name ?? "call_recordings-id",
    path: _91id_93HNo7QRNDLLMeta?.path ?? "/call_recordings/:id()",
    meta: _91id_93HNo7QRNDLLMeta || {},
    alias: _91id_93HNo7QRNDLLMeta?.alias || [],
    redirect: _91id_93HNo7QRNDLLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/call_recordings/[id].vue").then(m => m.default || m)
  },
  {
    name: index4w0yNFjVOOMeta?.name ?? "campaigns-id",
    path: index4w0yNFjVOOMeta?.path ?? "/campaigns/:id()",
    meta: index4w0yNFjVOOMeta || {},
    alias: index4w0yNFjVOOMeta?.alias || [],
    redirect: index4w0yNFjVOOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/campaigns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyAb1RfViXMeta?.name ?? "campaigns",
    path: indexyyAb1RfViXMeta?.path ?? "/campaigns",
    meta: indexyyAb1RfViXMeta || {},
    alias: indexyyAb1RfViXMeta?.alias || [],
    redirect: indexyyAb1RfViXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexNubuj8cJtvMeta?.name ?? "dashboard",
    path: indexNubuj8cJtvMeta?.path ?? "/dashboard",
    meta: indexNubuj8cJtvMeta || {},
    alias: indexNubuj8cJtvMeta?.alias || [],
    redirect: indexNubuj8cJtvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexlmdA6HVt6tMeta?.name ?? "help",
    path: indexlmdA6HVt6tMeta?.path ?? "/help",
    meta: indexlmdA6HVt6tMeta || {},
    alias: indexlmdA6HVt6tMeta?.alias || [],
    redirect: indexlmdA6HVt6tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexAdf4pdiVpBMeta?.name ?? "index",
    path: indexAdf4pdiVpBMeta?.path ?? "/",
    meta: indexAdf4pdiVpBMeta || {},
    alias: indexAdf4pdiVpBMeta?.alias || [],
    redirect: indexAdf4pdiVpBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexzp2evHtCPhMeta?.name ?? "inquiry",
    path: indexzp2evHtCPhMeta?.path ?? "/inquiry",
    meta: indexzp2evHtCPhMeta || {},
    alias: indexzp2evHtCPhMeta?.alias || [],
    redirect: indexzp2evHtCPhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: editor82mGmUAhEMMeta?.name ?? "ivr_trees-id-editor",
    path: editor82mGmUAhEMMeta?.path ?? "/ivr_trees/:id()/editor",
    meta: editor82mGmUAhEMMeta || {},
    alias: editor82mGmUAhEMMeta?.alias || [],
    redirect: editor82mGmUAhEMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/ivr_trees/[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: indexdnkxbmpfGAMeta?.name ?? "ivr_trees",
    path: indexdnkxbmpfGAMeta?.path ?? "/ivr_trees",
    meta: indexdnkxbmpfGAMeta || {},
    alias: indexdnkxbmpfGAMeta?.alias || [],
    redirect: indexdnkxbmpfGAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/ivr_trees/index.vue").then(m => m.default || m)
  },
  {
    name: verify_emailW68AxAuIMpMeta?.name ?? "profiles-user_id-verify_email",
    path: verify_emailW68AxAuIMpMeta?.path ?? "/profiles/:user_id()/verify_email",
    meta: verify_emailW68AxAuIMpMeta || {},
    alias: verify_emailW68AxAuIMpMeta?.alias || [],
    redirect: verify_emailW68AxAuIMpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/profiles/[user_id]/verify_email.vue").then(m => m.default || m)
  },
  {
    name: welcome_dashboardOrZRiws2Y2Meta?.name ?? "profiles-user_id-welcome_dashboard",
    path: welcome_dashboardOrZRiws2Y2Meta?.path ?? "/profiles/:user_id()/welcome_dashboard",
    meta: welcome_dashboardOrZRiws2Y2Meta || {},
    alias: welcome_dashboardOrZRiws2Y2Meta?.alias || [],
    redirect: welcome_dashboardOrZRiws2Y2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/profiles/[user_id]/welcome_dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexkvIATVul48Meta?.name ?? "profiles",
    path: indexkvIATVul48Meta?.path ?? "/profiles",
    meta: indexkvIATVul48Meta || {},
    alias: indexkvIATVul48Meta?.alias || [],
    redirect: indexkvIATVul48Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: index9uaL6ksF3fMeta?.name ?? "sub_users-users",
    path: index9uaL6ksF3fMeta?.path ?? "/sub_users/users",
    meta: index9uaL6ksF3fMeta || {},
    alias: index9uaL6ksF3fMeta?.alias || [],
    redirect: index9uaL6ksF3fMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/sub_users/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexIrY3cZnDtjMeta?.name ?? "terms_and_conditions",
    path: indexIrY3cZnDtjMeta?.path ?? "/terms_and_conditions",
    meta: indexIrY3cZnDtjMeta || {},
    alias: indexIrY3cZnDtjMeta?.alias || [],
    redirect: indexIrY3cZnDtjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexd2gCgHB3AcMeta?.name ?? "users-password-edit",
    path: indexd2gCgHB3AcMeta?.path ?? "/users/password/edit",
    meta: indexd2gCgHB3AcMeta || {},
    alias: indexd2gCgHB3AcMeta?.alias || [],
    redirect: indexd2gCgHB3AcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/users/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: index2Oj4dRzG0wMeta?.name ?? "users-password-new",
    path: index2Oj4dRzG0wMeta?.path ?? "/users/password/new",
    meta: index2Oj4dRzG0wMeta || {},
    alias: index2Oj4dRzG0wMeta?.alias || [],
    redirect: index2Oj4dRzG0wMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/users/password/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexvJaLQpuemPMeta?.name ?? "users-sign_in",
    path: indexvJaLQpuemPMeta?.path ?? "/users/sign_in",
    meta: indexvJaLQpuemPMeta || {},
    alias: indexvJaLQpuemPMeta?.alias || [],
    redirect: indexvJaLQpuemPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2629404069/src/pages/users/sign_in/index.vue").then(m => m.default || m)
  }
]